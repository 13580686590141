import Alpine from 'alpinejs'
import '../css/index.css'
import focusPlugin from '@alpinejs/focus'
import collapsePlugin from '@alpinejs/collapse'

window.Alpine = Alpine

Alpine.plugin(collapsePlugin)
Alpine.plugin(focusPlugin)
 
Alpine.start()